import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 393.000000 328.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,328.000000) scale(0.100000,-0.100000)">


<path d="M0 1640 l0 -1640 1965 0 1965 0 0 1640 0 1640 -1965 0 -1965 0 0
-1640z m1109 360 c64 -37 136 -78 161 -92 25 -14 66 -38 93 -54 l47 -29 0 -77
c0 -43 -3 -78 -7 -78 -5 1 -80 43 -168 95 l-160 94 -3 -260 -2 -259 -60 0 -60
0 0 375 0 376 22 -12 c11 -6 73 -42 137 -79z m499 -22 l11 -23 0 25 c1 19 3
22 10 11 5 -8 10 -176 10 -374 l1 -359 -57 33 c-32 18 -76 42 -97 53 -22 12
-49 29 -60 38 -11 10 -29 21 -40 24 -12 4 -39 18 -61 32 -22 14 -62 38 -90 53
l-50 27 -3 76 c-2 42 0 76 3 76 4 0 26 -13 50 -30 24 -16 46 -30 50 -30 3 0
34 -18 69 -40 35 -22 67 -40 71 -40 4 0 27 -13 51 -30 l44 -29 1 212 c1 117 1
236 0 265 l-1 52 39 0 c29 0 40 -5 49 -22z m373 -104 c76 -39 107 -132 74
-223 -26 -74 -78 -105 -175 -106 -83 0 -126 23 -157 85 -37 73 -22 185 30 229
52 42 161 50 228 15z m369 15 c81 -18 120 -74 120 -169 0 -102 -41 -154 -136
-172 -136 -24 -224 43 -224 172 0 88 33 139 107 166 46 16 74 17 133 3z m445
-11 c59 -27 72 -109 26 -160 l-29 -33 34 -67 34 -68 -63 0 -62 0 -27 60 c-20
46 -31 60 -47 60 -19 0 -21 -6 -21 -60 l0 -60 -55 0 -55 0 0 170 0 170 120 0
c71 0 130 -5 145 -12z m-1000 -417 c3 -5 -7 -11 -22 -13 -34 -4 -40 -40 -8
-47 17 -3 18 -1 6 13 -12 14 -10 16 13 16 21 0 25 -4 22 -22 -2 -18 -11 -24
-36 -26 -20 -2 -36 2 -41 10 -13 19 -11 52 3 66 14 14 54 16 63 3z m75 -26 c0
-28 4 -35 20 -35 11 0 20 -4 20 -10 0 -5 -15 -10 -33 -10 -32 0 -32 0 -29 45
5 53 22 61 22 10z m144 29 c10 -4 16 -18 16 -40 0 -28 -4 -35 -27 -40 -32 -8
-57 6 -61 35 -6 40 29 62 72 45z m129 -31 c8 -47 3 -53 -39 -53 -34 0 -34 0
-34 45 0 44 0 45 34 45 31 0 34 -3 39 -37z m109 -8 c15 -38 15 -45 3 -45 -8 0
-15 5 -15 10 0 6 -9 10 -20 10 -11 0 -20 -4 -20 -10 0 -5 -4 -10 -10 -10 -14
0 -13 15 4 55 20 47 38 44 58 -10z m68 10 c0 -28 4 -35 19 -35 11 0 23 -4 26
-10 4 -6 -8 -10 -29 -10 -36 0 -36 0 -36 45 0 25 5 45 10 45 6 0 10 -16 10
-35z m208 23 c7 -7 12 -21 12 -33 0 -30 -19 -45 -57 -45 -32 0 -33 1 -33 45 0
44 1 45 33 45 18 0 38 -5 45 -12z m115 -30 c19 -48 17 -54 -12 -36 -16 10 -23
10 -33 0 -19 -19 -30 -14 -22 11 25 81 43 88 67 25z m117 32 c0 -5 -7 -10 -15
-10 -10 0 -15 -11 -15 -35 0 -19 -4 -35 -10 -35 -5 0 -10 14 -10 31 0 19 -7
37 -17 45 -16 12 -13 13 25 14 23 0 42 -4 42 -10z m102 -35 c15 -38 15 -45 3
-45 -8 0 -15 5 -15 10 0 14 -38 12 -43 -2 -2 -7 -7 -10 -11 -7 -4 4 -1 25 6
48 17 55 39 53 60 -4z"/>
<path d="M1572 1950 c-12 -11 -26 -20 -32 -20 -17 0 -11 -19 9 -30 27 -14 30
-13 24 9 -2 10 3 26 12 35 25 24 13 30 -13 6z"/>
<path d="M1612 1850 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M1345 1551 c3 -5 -2 -12 -10 -14 -12 -4 -12 -5 1 -3 19 1 32 26 14
26 -6 0 -9 -4 -5 -9z"/>
<path d="M1293 1505 c-15 -17 -16 -25 -3 -25 5 0 14 9 20 20 12 23 1 26 -17 5z"/>
<path d="M1837 1792 c-12 -13 -17 -36 -17 -74 0 -64 19 -88 68 -88 41 0 62 31
62 90 0 60 -21 90 -64 90 -19 0 -40 -8 -49 -18z"/>
<path d="M2254 1802 c-33 -21 -44 -90 -22 -137 21 -47 100 -46 119 3 15 38 5
113 -17 129 -19 14 -62 17 -80 5z"/>
<path d="M2640 1780 c0 -28 2 -30 39 -30 46 0 70 22 51 45 -7 9 -29 15 -51 15
-37 0 -39 -2 -39 -30z"/>
<path d="M1967 1437 c-3 -8 -2 -20 3 -27 11 -18 42 -4 38 17 -4 22 -34 29 -41
10z"/>
<path d="M2090 1440 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M2098 1403 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2215 1430 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2480 1425 c0 -16 6 -25 15 -25 9 0 15 9 15 25 0 16 -6 25 -15 25 -9
0 -15 -9 -15 -25z"/>
<path d="M2825 1430 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
